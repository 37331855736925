var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.trackLoaded
    ? _c(
        "b-card",
        {
          staticClass: "mt-4",
          attrs: {
            header:
              "Track " +
              (_vm.track.TrackSeq ? _vm.track.TrackSeq : "") +
              " - " +
              _vm.track.Title +
              " - " +
              _vm.track.Attribution
          }
        },
        [
          _c("field", {
            attrs: {
              defs: _vm.fieldDefs,
              name: "ISRC",
              model: _vm.track,
              disabled: _vm.isrcChecking,
              errors: _vm.errors
            },
            on: { change: _vm.isrcChange }
          }),
          _c(
            "b-alert",
            {
              attrs: { show: _vm.isrcNotAvailable != null, variant: "danger" }
            },
            [
              _vm._v(
                'ISRC "' + _vm._s(_vm.isrcNotAvailable) + '" is not available'
              )
            ]
          ),
          _c(
            "b-alert",
            {
              attrs: { show: _vm.useExistingTrack != false, variant: "success" }
            },
            [
              _vm._v(" This ISRC is already known as:"),
              _c("br"),
              _vm._v(
                " Provider: " + _vm._s(_vm.useExistingTrack.ProviderDescription)
              ),
              _c("br"),
              _vm._v(
                " Sub Provider: " +
                  _vm._s(_vm.useExistingTrack.SubProviderDescription)
              ),
              _c("br"),
              _vm._v(
                " Attribution: " + _vm._s(_vm.useExistingTrack.Attribution)
              ),
              _c("br"),
              _vm._v(
                " Title: " +
                  _vm._s(_vm.useExistingTrack.Title) +
                  " " +
                  _vm._s(_vm.useExistingTrack.TitleVersion)
              ),
              _c("br"),
              _vm._v(
                " This track will be linked to this release. Please enter a new ISRC if you do not want to link this track to this release. "
              )
            ]
          ),
          !_vm.useExistingTrack && !_vm.isrcChecking
            ? [
                _c("page-snippet", { attrs: { name: "capitalization" } }),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        copyToTracks: _vm.trackCount > 1,
                        name: "TitleLanguage",
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "LyricsLanguage",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c("field", {
                  attrs: {
                    defs: _vm.fieldDefs,
                    name: "Title",
                    errors: _vm.errors,
                    model: _vm.track
                  }
                }),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        copyToTracks: _vm.trackCount > 1,
                        name: "VersionTitle",
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "contributors",
                  _vm._g(
                    {
                      staticClass: "mt-5",
                      attrs: {
                        copyToTracks: _vm.trackCount > 1,
                        defs: _vm.fieldDefs,
                        type: "track",
                        model: _vm.track,
                        validationErrors: _vm.errors
                      },
                      on: { change: _vm.updateAttribution }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.TopLevel",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.RecordingVersion",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.MainGenre",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.SubGenre",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.OtherInfluences",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.Occasion",
                        max: 5,
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.Tempo",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.ParentalWarningType",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.TrackPriceTier",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Tags.Channels",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "PLine",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "CLine",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "AllowRecompilation",
                        copyToTracks: _vm.trackCount > 1,
                        boolean: true,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "AllowSingleSales",
                        copyToTracks: _vm.trackCount > 1,
                        boolean: true,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "BPM",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c("field", {
                  attrs: {
                    defs: _vm.fieldDefs,
                    name: "Attributes.YouTube",
                    model: _vm.track,
                    errors: _vm.errors
                  }
                }),
                _c("page-snippet", {
                  staticClass: "mt-5",
                  attrs: { name: "public-domain" }
                }),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Attributes.Is Public Domain",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Attributes.MasteredDate",
                        copyToTracks: _vm.trackCount > 1,
                        date: true,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Attributes.IsRemastered",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _vm.getAttribute(_vm.track, "IsRemastered") == "True"
                  ? _c(
                      "field",
                      _vm._g(
                        {
                          attrs: {
                            defs: _vm.fieldDefs,
                            date: true,
                            name: "Attributes.ReMasteredDate",
                            copyToTracks: _vm.trackCount > 1,
                            model: _vm.track,
                            errors: _vm.errors
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                _c(
                  "field",
                  _vm._g(
                    {
                      attrs: {
                        defs: _vm.fieldDefs,
                        name: "Attributes.PreviewStartSeconds",
                        copyToTracks: _vm.trackCount > 1,
                        model: _vm.track,
                        errors: _vm.errors
                      }
                    },
                    _vm.$listeners
                  )
                ),
                _vm.getAttribute(_vm.track, "PreviewStartSeconds") != ""
                  ? _c(
                      "field",
                      _vm._g(
                        {
                          attrs: {
                            defs: _vm.fieldDefs,
                            name: "Attributes.PreviewDuration",
                            copyToTracks: _vm.trackCount > 1,
                            model: _vm.track,
                            errors: _vm.errors
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                _vm.getTag(_vm.track, "TopLevel") == "Classical"
                  ? _c(
                      "field",
                      _vm._g(
                        {
                          attrs: {
                            copyToTracks: _vm.trackCount > 1,
                            defs: _vm.fieldDefs,
                            name: "WorkTitle",
                            model: _vm.track,
                            errors: _vm.errors
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                _vm.getTag(_vm.track, "TopLevel") == "Classical"
                  ? _c(
                      "field",
                      _vm._g(
                        {
                          attrs: {
                            copyToTracks: _vm.trackCount > 1,
                            defs: _vm.fieldDefs,
                            name: "Key",
                            model: _vm.track,
                            errors: _vm.errors
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                _vm.getTag(_vm.track, "TopLevel") == "Classical"
                  ? _c(
                      "field",
                      _vm._g(
                        {
                          attrs: {
                            copyToTracks: _vm.trackCount > 1,
                            defs: _vm.fieldDefs,
                            name: "ClassicalCatalogNr",
                            model: _vm.track,
                            errors: _vm.errors
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                _vm.getTag(_vm.track, "TopLevel") == "Classical"
                  ? _c(
                      "field",
                      _vm._g(
                        {
                          attrs: {
                            copyToTracks: _vm.trackCount > 1,
                            defs: _vm.fieldDefs,
                            name: "MovementNr",
                            model: _vm.track,
                            errors: _vm.errors
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                _vm.getTag(_vm.track, "TopLevel") == "Classical"
                  ? _c(
                      "field",
                      _vm._g(
                        {
                          attrs: {
                            copyToTracks: _vm.trackCount > 1,
                            defs: _vm.fieldDefs,
                            name: "MovementTitle",
                            model: _vm.track,
                            errors: _vm.errors
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                _c("field", {
                  attrs: {
                    defs: _vm.fieldDefs,
                    name: "SourceSubProviderID",
                    errors: _vm.errors,
                    "hide-if-one-option": true,
                    model: _vm.track
                  }
                }),
                _c("field", {
                  attrs: {
                    defs: _vm.fieldDefs,
                    name: "SourceTermsetID",
                    model: _vm.track,
                    "hide-if-one-option": true
                  },
                  on: { change: _vm.termSetChange }
                }),
                _vm.termsetVariationAllowed
                  ? _c(
                      "div",
                      [
                        _c("page-snippet", {
                          staticClass: "mt-5",
                          attrs: { name: "track-create-rights" }
                        }),
                        _vm.track.Rights
                          ? _c("territory", {
                              staticClass: "mt-4",
                              attrs: {
                                defs: _vm.fieldDefs,
                                errors: _vm.errors
                              },
                              on: {
                                errors: function($event) {
                                  return _vm.$emit("errors", {
                                    Territory: $event
                                  })
                                }
                              },
                              model: {
                                value: _vm.track.Rights[0].Territory,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.track.Rights[0],
                                    "Territory",
                                    $$v
                                  )
                                },
                                expression: "track.Rights[0].Territory"
                              }
                            })
                          : _vm._e(),
                        _vm.track.Rights
                          ? _c("field", {
                              attrs: {
                                defs: _vm.fieldDefs,
                                name: "Rights.AllowSubscription",
                                model: _vm.track.Rights[0]
                              }
                            })
                          : _vm._e(),
                        _vm.track.Rights
                          ? _c("field", {
                              attrs: {
                                defs: _vm.fieldDefs,
                                name: "Rights.AllowAdvertising",
                                model: _vm.track.Rights[0]
                              }
                            })
                          : _vm._e(),
                        _vm.track.Rights
                          ? _c("field", {
                              attrs: {
                                defs: _vm.fieldDefs,
                                name: "Rights.AllowTransaction",
                                model: _vm.track.Rights[0]
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.termsetVariationAllowed ? _c("div") : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }